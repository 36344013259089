html {
  overflow-x: hidden;
  left: 0px;
  right: 0px;
}
body {
  overflow-x: hidden;
  left: 0px;
  right: 0px;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Mingzat-Regular";
  src: local("Mingzat-Regular"),
   url("./assets/fonts/Mingzat-Regular.ttf") format("truetype");
  font-weight: bold;
  }

@font-face {
  font-family: "NeutraDisplay-Light";
  src: local("NeutraDisplay-Light"),
   url("./assets/fonts/NeutraDisplay-Light.ttf") format("truetype");
  font-weight: bold;
  }

@font-face {
  font-family: "NeutraDisplay-Bold";
  src: local("NeutraDisplay-Bold"),
   url("./assets/fonts/NeutraDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  }
@font-face {
  font-family: "Arkhip-regular";
  src: local("Arkhip-regular"),
   url("./assets/fonts/Arkhip-regular.ttf") format("truetype");
  font-weight: bold;
  }
@font-face {
  font-family: "CreatoDisplay-Medium";
  src: local("CreatoDisplay-Medium"),
   url("./assets/fonts/CreatoDisplay-Medium.otf") format("truetype");
  font-weight: bold;
  }
@font-face {
  font-family: "CreatoDisplay-Regular";
  src: local("CreatoDisplay-Regular"),
   url("./assets/fonts/CreatoDisplay-Regular.otf") format("truetype");
  font-weight: bold;
  }
@font-face {
  font-family: "Comfortaa-Regular";
  src: local("Comfortaa-Regular"),
   url("./assets/fonts/Comfortaa-Regular.ttf") format("truetype");
  font-weight: bold;
  }
