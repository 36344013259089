@use '../../../../../components/themes/Themes' as *;

.main-step-4-tablet{
  width: 650px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.content-step-4-tablet{
  
  margin: 70px auto 0px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  }
.titleStep1-tablet{
    background-color: rgb(216, 216, 216);
    width: 100%;
}
.row-main-step-4{
  
}
.title-content-child-subcategory-constructions-tablet{
  width: 100px;
  text-align: center;
  padding-top: 40px;
}
.id-item-detail-tablet{
  font-family: $main-font-family;
  color:$color-of-the-year;
  font-size: 12px;
  
}
.item-detail-tablet{
  font-family: $main-font-family;
  color:rgb(94, 94, 94);
  font-size: 12px;
}
.detail-ids-tablet{
  text-align: left;
  margin: 30px;
}