@use '../../../../../components/themes/Themes' as *;
.main-step-4-mobile{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 20px 0px 20px;
  width: 90%;
  margin: 0px auto;
  
    
  }
.titleStep1-mobile{
    background-color: rgb(216, 216, 216);
    width: 100%;

}
.content-step-4-mobile{
  
  margin: 70px auto 0px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  }

.subcategory-contructions-step-4-mobile{
  font-family: "Mingzat-Regular";
  font-size: 14px;
  margin: 0px auto 0px auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
 
  
}

.title-content-child-subcategory-constructions-mobile{
  width: 100px;
  text-align: center;
  padding-top: 40px;
}
.id-item-detail-mobile{
  font-family: $main-font-family;
  color:$color-of-the-year;
  font-size: 12px;
  
}
.item-detail-mobile{
  font-family: $main-font-family;
  color:rgb(94, 94, 94);
  font-size: 12px;
}
.detail-ids-mobile{
  text-align: left;
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
}