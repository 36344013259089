.main-view-big-image-on-screen-hidden{
    display: none;
     -webkit-tap-highlight-color: transparent;
}

.main-view-big-image-on-screen-visible{
    position:fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    -webkit-tap-highlight-color: transparent;
}
.image-view-big-image-on-screen{
    display: flex;
    justify-content: center;
    margin: 0px auto;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}
.arrows-view-big-image-on-screen{
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    position: absolute;
    width: 72%;
    z-index: 2;
    color:rgb(23, 23, 23);
    -webkit-tap-highlight-color: transparent;
}

.black-screen{
    background-color: black;
    position:absolute;
    width: 100%;
    height: 100%;
    background-size: 100% 100% ;
    top:0;
    left: 0;
    opacity: .7;
    -webkit-tap-highlight-color: transparent;
}
.image-view-on-screen{
    border: 2px white;
    border-radius: 10px;
    z-index: 1;
    width: 90%;
     -webkit-tap-highlight-color: transparent;
}
.arrow-view-big-image{
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid white;
    padding: 10px;
    border-radius: 50%;
    -webkit-tap-highlight-color: transparent;
}
