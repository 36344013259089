.main-step-1{
    border: 1px solid;
    border-radius: 50%;
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.titleStep1 {
    background-color: rgb(216, 216, 216);
    width: 100%;

}

.content-category-step_1 {
    display: flex;
    justify-content: center;
}

