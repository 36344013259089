@use '../../../components/deviceResolution/DeviceResolution' as *;

@media only screen and (min-width: 0px) and (max-width: $tablet){
  .main-container{
    display: flex;
    justify-content: center;
    gap:10px;
    flex-wrap: wrap;
    width: 80%;
    margin: 0px auto 0px auto;
    padding: 130px;
  }
}
@media only screen and (min-width: $tablet) and (max-width: $desktop){
  .main-container{
    display: flex;
    justify-content: center;
    gap:10px;
    flex-wrap: wrap;
    width: 600px;
    margin: 160px auto 0px auto;
  }

}

@media only screen and (min-width: $desktop){
  .main-container{
    display: flex;
    justify-content: center;
    gap:10px;
    flex-wrap: wrap;
    width: 600px;
    margin: 160px auto 0px auto;
  }
}


.member-team-container{
  height: auto;
  background-color: rgba(3, 3, 3, 0);
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "Comfortaa-Regular";
  margin: 20px;
  .bg{
      padding: 10px;
      position: relative;
      margin-top: 116px;
      padding-top: 32px;
      background-color: rgb(40, 40, 40);
      width: 190px;
      height: 180px;
      border-radius: 10px;
      
      .name{
        position: relative;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
      }

      .roll{
        position: relative;
        color: rgb(250, 0, 117);
        font-weight: bold;
        font-size: 12px;
        padding-bottom: 6px;
      }

      .studies{
        position: relative;
        color: rgb(151, 151, 151);
        font-weight: bold;
        font-size: 11px;
        height: 40px;
      }

        .contact{
          position: static;
          margin: 30px auto 0px auto;
        }
    
      .icons-contact{
        padding: 0px 10px;
        color: rgb(255, 0, 157);
        
      }
  }
}

.image-border{
  position: absolute;
  margin: 0px auto 0px auto;
  width: 140px;
  height: 140px;
  text-align: center;
  background-color: rgba(139, 139, 139, 0);
  border: 1px solid rgb(250, 0, 117);
  border-spacing: 45px;
  z-index: 1;
   border-radius: 50%;
  
}

.image{
  position: absolute;
  margin: 10px auto 0px auto;
  width: 120px;
  height: 120px;
  text-align: center;
  background-color: rgb(218, 218, 218);
  z-index: 1;
   border-radius: 50%;
}