@use '../../../../components/deviceResolution/DeviceResolution' as *;

@media only screen and (min-width: $desktop){
  .container-services-page{
    height: 700px; 
  }
  .main-services-page{
    position:absolute;
    left:0;
    right:300px;
    margin-left:auto;
    margin-right:auto;
     display: flex;
     justify-content: center;
     flex-direction: column;
     margin-top: 240px;
     gap: 5px 0px;
     align-items: center;
     
  }
  .item-title-services-page{
    padding-top: 10px;
    padding-left: 70px;
    text-align: left;
    width: 360px;
    height: 40px;
    box-shadow: 0 4px 4px 0 rgba(101, 101, 101, 0.2), 0 2px 4px 0 rgba(174, 174, 174, 0.19);
    cursor: pointer;
    font-family: 'Comfortaa-Regular';
    font-size: 12px;
  }
  .item-title-services-page:hover{
    padding-top: 12px;
    padding-left: 12px;
    text-align: left;
    width: 390px;
    height: 40px;
    color: white;
    background-color: black;
  }
.container-content-services-page{
  position:absolute;
  left:300px;
  right:0;
  margin-left:auto;
  margin-right:auto;
  margin-top: 140px;
  height: 400px;
  width: 400px;
  background-color: #434343;
  border-radius: 50%;
  border: 1px solid rgb(172, 172, 172);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 90px;
  
}
  .description-service-hide{
    display: none;
  }
  .description-service-visible{
    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    font-family: 'Comfortaa-Regular';
    font-size: 12px;
   color: aliceblue;

  }
  .text-description-services{
    
  }
}
