@use '../../../../../components/themes/Themes' as *;
.titleStep2-mobile{
    background-color: rgb(216, 216, 216);
    width: 100%;
}
.main-container-step2-mobile{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 6px 0px;
    width: 400px;
}

.element-input-mobile{
    border-radius: 14px;
    color: $color-of-the-year;
    border: 1px solid $color-of-the-year;
    padding: 6px 10px;
}

.element-input-mobile:focus{
    border: 1px solid $color-of-the-year;
    box-shadow: 0 0 4px $color-of-the-year;
    outline-offset: 0px;
    outline: none;
}

::placeholder-mobile{
    color: rgb(255, 171, 203);
    opacity: 1;
    font-family: "Mingzat-Regular";
    text-transform: uppercase;
    font-style: italic;
    font-size: 14px;
  }

::placeholder-mobile:hover{
    border: 1px solid $color-of-the-year;
  }
