@use '../../../../../components/themes/Themes' as *;
.main-step-3-desktop{
  width: 650px;
  border: 1px solid;
}
.titleStep3-desktop {
  background-color: rgb(216, 216, 216);
  width: 100%;

}

.main-title-category-construction-step-3-desktop {
  font-family: "Mingzat-Regular";
  font-size: 14px;
  text-transform: uppercase;
}

.category-contructions-desktop {
  margin: 30px 20px 0px 20px;
  display: flex;
  flex-direction: column;
 
}

.subcategory-contructions-desktop {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius:010px;
  font-family: "Mingzat-Regular";
  font-size: 14px;
  margin: 30px 0px 0px 0px;
  width: 100%;
  height: max-content;


  .vertical-line-desktop {
    width: 5px;
    height: 100%;
    float: right;
    background-color: $color-of-the-year;
    z-index: 4;
  }
}
.footer-category-desktop{
  display: flex;
}

.image-border-configurator-step-3-desktop {
  margin: -6px -6px 6px -6px;
  height: 82px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(68, 68, 68);
  z-index: -1;
}

.title-category-step-3-desktop {
  width: 100%;
  height: 50px;
  background-color: $color-of-the-year;
  display: flex;
  justify-content: center;
  align-items: center;
  outline:solid;
  outline-width: 2px;
  outline-offset:6px;
  outline-color: rgb(255, 189, 203);
  span{
    color: white;
    font-family: "Mingzat-Regular";
    font-size: 14px;
    text-transform: uppercase;
    
}
}

.title-subcategory-constructions-desktop {
  background-color: rgb(41, 41, 41);
  border-radius:06px;
  height: 40px;
  width:200px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-subcategory-constructions-desktop {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  
  .title-content-child-subcategory-constructions-desktop{
    width: 100px;
    text-align: center;
    padding-top: 40px;
  }

  .break{
    flex-grow: 1
  }
  .categories-2-container-desktop{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
  
}
.content-subcategory-constructions-type_1-desktop{
  
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  
  .title-content-child-subcategory-constructions-desktop{
    width: 100px;
    text-align: center;
    padding-top: 40px;
  }

  
}
.categories-2-container-desktop{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.form-check-step-3-desktop{
  background: rgb(252,145,89);
  background: linear-gradient(106deg, rgba(84, 215, 230, 0) 0%, rgba(248, 107, 220, 0) 100%);
  width:130px;
  height: 100px;
  border-radius:0px;
  border: 2px solid $color-of-the-year;
  font-family: "Mingzat-Regular";
  font-size: 14px;
  text-transform: uppercase;
  color: $color-of-the-year;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  margin: 10px;
}

.form-check-step-3-desktop:hover{
  background-color: black;
  border: 4px solid rgb(242, 242, 242);
  color: rgb(255, 255, 255);

}
.category-item-step-3-desktop{
   font-size: 14px;
   font-style: italic;
   color: rgb(0, 0, 0);
}


