@use '../../../../../components/themes/Themes' as *;
.main-step-3-mobile{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 88%;
  margin: 0px auto;
  padding: 0px 0px 30px 0px;
}

.titleStep3-mobile {
  background-color: rgb(216, 216, 216);
  width: 100%;

}

.main-title-category-construction-step-3-mobile {
  font-family: "Mingzat-Regular";
  font-size: 14px;
  text-transform: uppercase;
}

.category-contructions-mobile {
  margin: 30px 20px 0px 20px;
  width: 90%;
  
}

.subcategory-contructions-mobile {
  font-family: "Mingzat-Regular";
  font-size: 14px;
  margin: 30px auto 0px auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
 
  
}
.footer-category-mobile{
  display: flex;
}

.image-border-configurator-step-3-mobile {
  margin: -6px -6px 6px -6px;
  height: 82px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(68, 68, 68);
  z-index: -1;
}

.title-category-step-3-mobile {
  width: 100%;
  height: 50px;
  background-color: $color-of-the-year;
  display: flex;
  justify-content: center;
  align-items: center;
  outline:solid;
  outline-width: 2px;
  outline-offset:6px;
  outline-color: rgb(255, 189, 203);
  span{
    color: white;
    font-family: "Mingzat-Regular";
    font-size: 14px;
    text-transform: uppercase;
    
}
}

.title-subcategory-constructions-mobile {
  background-color: rgb(41, 41, 41);
  border-radius:06px;
  height: 40px;
  width:200px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-subcategory-constructions-mobile {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  
  .title-content-child-subcategory-constructions-mobile{
    width: 100px;
    text-align: center;
    padding-top: 40px;
  }

  .break-mobile{
    flex-grow: 1
  }
  .categories-2-container-mobile{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
  
}

.categories-2-container-mobile{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.category-item-step-3-mobile{
   font-size: 12px;
   font-style: italic;
   color: rgb(0, 0, 0);
}


