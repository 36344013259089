@use '../deviceResolution/DeviceResolution' as *;
@use '../themes/Themes' as *;

@media only screen and (min-width: 0px) and (max-width: $mobile) {
.selected {
    background: black;
    border: 1px solid rgb(212, 212, 212);
    color: rgb(255, 255, 255);
    width: 90px;
    height: 90px;
    border-radius: 4px;
    font-family: "Mingzat-Regular";
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px;
}

.deselected {
    background: rgb(252, 145, 89);
    background: linear-gradient(106deg, rgba(84, 215, 230, 0) 0%, rgba(248, 107, 220, 0) 100%);
    width: 90px;
    height: 90px;
    border-radius: 4px;
    border: 1px solid $color-of-the-year;
    font-family: "Mingzat-Regular";
    font-size: 13px;
    text-transform: uppercase;
    color: $color-of-the-year;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px;
}

.deselected:hover{
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 1px solid white;
  }
}
@media only screen and (min-width: $mobile) and (max-width: $tablet) {
.selected {
    background: black;
    border: 1px solid rgb(212, 212, 212);
    color: rgb(255, 255, 255);
    width: 130px;
    height: 100px;
    border-radius: 4px;
    font-family: "Mingzat-Regular";
    font-size: 16px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px;
}

.deselected {
    background: rgb(252, 145, 89);
    background: linear-gradient(106deg, rgba(84, 215, 230, 0) 0%, rgba(248, 107, 220, 0) 100%);
    width: 130px;
    height: 100px;
    border-radius: 4px;
    border: 1px solid $color-of-the-year;
    font-family: "Mingzat-Regular";
    font-size: 16px;
    text-transform: uppercase;
    color: $color-of-the-year;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px;
}

.deselected:hover{
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 1px solid white;
  }
}
@media only screen and (min-width: $tablet) {
    .selected {
        background: black;
        border: 1px solid rgb(212, 212, 212);
        color: rgb(255, 255, 255);
        width: 130px;
        height: 100px;
        border-radius: 4px;
        font-family: "Mingzat-Regular";
        font-size: 16px;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 10px;
    }
    
    .deselected {
        background: rgb(252, 145, 89);
        background: linear-gradient(106deg, rgba(84, 215, 230, 0) 0%, rgba(248, 107, 220, 0) 100%);
        width: 130px;
        height: 100px;
        border-radius: 4px;
        border: 1px solid $color-of-the-year;
        font-family: "Mingzat-Regular";
        font-size: 16px;
        text-transform: uppercase;
        color: $color-of-the-year;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 10px;
    }
    
    .deselected:hover{
        background: rgb(0, 0, 0);
        color: rgb(255, 255, 255);
        border: 1px solid white;
      }
    }