@use '../../../../components/themes/Themes' as *;
@use '../../../../components/deviceResolution/DeviceResolution' as *;

@media only screen and (min-width: $desktop) {
  .container-project-page {
    margin-top:120px
  }

  .box-project {
    display: flex;
    justify-content: center;
    flex-direction: column;
    
  }

  .row-project {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:20px auto;
    width: 60%;
  }

  .left-content {
    overflow: hidden;
  }

  .right-content {
    overflow: hidden;

  }

  .title-project-from-projects-page {
    width: 100%;
    font-size: 12px;
    color: $color-of-the-year;
    text-decoration: none;
    font-family: "Comfortaa-Regular";
    padding-top: 8px;
  }


  .subtitle-project-from-projects-page {
    width: 100%;
    font-size: 12px;
    font-style: italic;
    color: rgb(184, 184, 184);
    text-decoration: none;
    font-family: "Comfortaa-Regular";
  }

  .location-project-from-projects-page {
    width: 100%;
    font-size: 12px;
    font-style: italic;
    color: rgb(184, 184, 184);
    text-decoration: none;
    font-family: "Comfortaa-Regular";

  }


  .thumb-project-description {
    cursor: pointer;
    width: 400px;
    height: 80px;
    margin: 144px 0px 0px 0px;
    z-index: 1;
  }

  .thumb-project-image {
    transition: .3s ease;
    width: 340px;
    height: 340px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgb(255, 255, 255);
  }

  .project-image {
    transition: .3s ease;
    object-fit: cover;
    width: 260px;
    height: 260px;
    padding: 30px;
    border: 1px solid $color-of-the-year;
    border-radius: 50%;
    overflow: hidden;
   
  }
  .item-bg{
    box-shadow: 0 4px 4px 0 rgba(101, 101, 101, 0.2), 0 2px 4px 0 rgba(174, 174, 174, 0.19);
    height: 70px;
  }
  /* mouse over link */
  .project-image:hover {
    transform: scale(1.6);
  }

  .item-bg:hover {
   background-color: black;
   color: white;
  }

  /* selected link */
  .thumb-project:active {
    color: yellow;
  }

  .left-content-hover {
    position: relative;
    text-align: center;
    width: 360px;
    height: 360px;
    overflow: hidden;
    color: red;
  }
}