@use '../deviceResolution/DeviceResolution' as *;
@media only screen and (min-width: 0px) and (max-width: $tablet) {
.container-warning-complete-field {
  font-family: "Mingzat-Regular";
  font-size: 16px;
  font-style: italic;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  margin: 0px auto;
  pointer-events: none;
  
}
.console-warning {
  opacity: 0;
}

.console-warning-visible {
  width: 100%;
  height: 100px;
  margin: 0px auto;
  background-color: rgb(38, 38, 38);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: white;
  animation-name: show-warning-message;
  animation-duration: .5s;
}
.console-warning-step-1-2-visible{
  margin: 100px auto 0px auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  background-color: rgb(0, 0, 0);
  animation-name: show-warning-message;
  animation-duration: .5s;
}
.console-warning-step-3-4-visible{
  margin: 100px auto 0px auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  background-color: rgb(0, 0, 0);
  animation-name: show-warning-message;
  animation-duration: .5s;
}
.console-warning-step-1-2-hide{
  margin: 100px auto 0px auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  animation-name: hide-warning-message;
  animation-duration: .2s;
}
.console-warning-step-3-4-hide{
  margin: 100px auto 0px auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  animation-name: hide-warning-message;
  animation-duration: .2s;
}
.console-warning-hide {
  width: 100%;
  height: 100px;
  margin: 0px auto;
  background-color: rgb(38, 38, 38);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: white;
  opacity:0;
  animation-name: hide-warning-message;
  animation-duration: .5s;
}


.text-console-warning{
  color:white;
}

@keyframes show-warning-message{
  0% {opacity: 0}
  100% {opacity: 1}
}

@keyframes hide-warning-message{
  0% {opacity: 1}
  100% {opacity: 0}
}
}
@media only screen and (min-width: $tablet) and (max-width: $desktop) {
.container-warning-complete-field {
  font-family: "Mingzat-Regular";
  font-size: 16px;
  font-style: italic;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  margin: 0px auto;
  pointer-events: none;
  
}
.console-warning {
  opacity: 0;
}

.console-warning-visible {
  width: 100%;
  height: 100px;
  margin: 0px auto;
  background-color: rgb(38, 38, 38);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: white;
  animation-name: show-warning-message;
  animation-duration: .5s;
}
.console-warning-step-1-2-visible{
  margin: 286px auto 0px auto;
  border: 1px solid;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  background-color: rgb(70, 70, 70);
  animation-name: show-warning-message;
  animation-duration: .5s;
}
.console-warning-step-3-4-visible{
  margin: 286px auto 0px auto;
  border: 1px solid;
  width: 650px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  background-color: rgb(70, 70, 70);
  animation-name: show-warning-message;
  animation-duration: .5s;
}
.console-warning-step-1-2-hide{
  margin: 286px auto 0px auto;
  border: 1px solid;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  opacity: 0;
  background-color: rgb(70, 70, 70);
  animation-name: hide-warning-message;
  animation-duration: .2s;
}
.console-warning-step-3-4-hide{
  margin: 286px auto 0px auto;
  border: 1px solid;
  width: 650px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  opacity: 0;
  background-color: rgb(70, 70, 70);
  animation-name: hide-warning-message;
  animation-duration: .2s;
}
.console-warning-hide {
  width: 100%;
  height: 100px;
  margin: 0px auto;
  background-color: rgb(38, 38, 38);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: white;
  opacity:0;
  animation-name: hide-warning-message;
  animation-duration: .5s;
}


.text-console-warning{
  color:white;
}

@keyframes show-warning-message{
  0% {opacity: 0}
  100% {opacity: 1}
}

@keyframes hide-warning-message{
  0% {opacity: 1}
  100% {opacity: 0}
}
}
@media only screen and (min-width: $desktop){
.container-warning-complete-field {
  font-family: "Mingzat-Regular";
  font-size: 16px;
  font-style: italic;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  margin: 0px auto;
  pointer-events: none;
  
}
.console-warning {
  opacity: 0;
}

.console-warning-visible {
  width: 100%;
  height: 100px;
  margin: 0px auto;
  background-color: rgb(38, 38, 38);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: white;
  animation-name: show-warning-message;
  animation-duration: .5s;
}
.console-warning-step-1-2-visible{
  margin: 309px auto 0px auto;
  border: 1px solid;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  background-color: rgb(70, 70, 70);
  animation-name: show-warning-message;
  animation-duration: .5s;
}
.console-warning-step-3-4-visible{
  margin: 308px auto 0px auto;
  border: 1px solid;
  width: 650px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  background-color: rgb(70, 70, 70);
  animation-name: show-warning-message;
  animation-duration: .5s;
}
.console-warning-step-1-2-hide{
  margin: 309px auto 0px auto;
  border: 1px solid;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  opacity: 0;
  background-color: rgb(70, 70, 70);
  animation-name: hide-warning-message;
  animation-duration: .2s;
}
.console-warning-step-3-4-hide{
  margin: 308px auto 0px auto;
  border: 1px solid;
  width: 650px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  opacity: 0;
  background-color: rgb(70, 70, 70);
  animation-name: hide-warning-message;
  animation-duration: .2s;
}
.console-warning-hide {
  width: 100%;
  height: 100px;
  margin: 0px auto;
  background-color: rgb(38, 38, 38);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: white;
  opacity:0;
  animation-name: hide-warning-message;
  animation-duration: .5s;
}


.text-console-warning{
  color:white;
}

@keyframes show-warning-message{
  0% {opacity: 0}
  100% {opacity: 1}
}

@keyframes hide-warning-message{
  0% {opacity: 1}
  100% {opacity: 0}
}
}