@use '../../../../components/deviceResolution/DeviceResolution' as *;


@media only screen and (min-width: $tablet) and (max-width: $desktop){
  .main-services-page{
    margin:70px auto;
  }
.item-title-services-page {
  margin: 10px auto;
  padding-top: 30px;
  text-align: center;
  width: 50%;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(101, 101, 101, 0.2), 0 2px 4px 0 rgba(174, 174, 174, 0.19);
  cursor: pointer;
  font-family: 'Comfortaa-Regular';
  font-size: 16px;
  z-index: 2;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 4px;

}

.item-title-services-page-selected {
  color:red;
}

.description-service-hide {
  display: none;
}

.description-service-visible {
  font-family: 'Comfortaa-Regular';
  font-size: 12px;
  margin: -10px auto 0px auto;
  color: white;
  width: 65%;
  background-color: #434343;

  border: 1px solid rgb(172, 172, 172);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 40px 10px;
  z-index: 1;
  
}

.text-description-services {}
}