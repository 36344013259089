@use '../../../../components/deviceResolution/DeviceResolution' as *;

@media only screen and (min-width: $desktop){
  .main-page{
    
    font-family: "Mingzat-Regular";
    font-size: 16px;
    margin:32px 0px 0px 0px;
    text-align: center;
    display: flex;
    height: 700px;
    }
  
    .cover{
      
      
    }
    .photos-cover{
      background-color: rgb(50, 50, 50);
    }
    .gadget-cover-desktop{
      width: 100%;
      background-color: aqua;
      position: absolute;
      margin: 0px auto;
      left:0;
      right:0;
      padding: 560px 0px 0px 0px;
      background-color: rgba(255, 255, 255, 0);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1px;
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      z-index: 1;
      

      .item-gadget-cover-desktop{
        width: 100px;
        height: 100px;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        
      }
      .item-gadget-cover-desktop:hover{
        
        background-color: rgba(255, 255, 255, 0.274);
      }
    }
  
    .bg-text{
      max-width: 700px;
      height: auto;
      background-color: rgba(5, 2, 34, 0.821);
      border-radius: 0px 0px 5px 30px;
      position: relative;
      color: rgb(202, 202, 202);
      margin: -260px auto 0px auto;
      z-index: 9;
      
    }
  
    .text{
      padding: 30px;
      text-align: center;
      margin: 0px auto;
    }
    .swiper {
      width: 100%;
      height: 100%;
      margin: 0px auto;
    }
  
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
  
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .carousel-cover-home-page{
      left: 0; 
      right: 0; 
      margin-left: auto; 
      position: absolute;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap:10px;
      height: 500px;
     
    }
    .item-carousel-cover-home-page{
      width: 100%;
      height: 660px;
      object-fit: cover;
      
    }
    .item-carousel-cover-home-page-visible-hover{
     
      left: 0;
      width: 100%;
      height: 660px;
      object-fit: cover;
      position: absolute;
      z-index: 0;
      
    }
    
    .item-carousel-cover-home-page-hide-hover{
      
      width: 100%;
      height: 660px;
      object-fit: cover;
      
      display: none;
    }
    .title-cover-home-page{
      
      margin-top: 0px; 
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto; 
      width: 400px;
      position: absolute;
      color: #fff;
      text-align: right;
      border: 1px solid rgb(255, 8, 136);
      font-size: 30px;
      
    }
    .description-cover-home-page{
      position: absolute;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.164);
      width: 500px;
      top:230px;
      margin: -20px 0px 0px -20px;
      color:white;
      padding: 50px;
      border: 2 solid rgb(255, 30, 221);
      border-radius: 3px;
      pointer-events: none;
      animation-name: description-cover-home-page-animation;
      animation-duration: 1.7s;
    }
      
      @keyframes description-cover-home-page-animation {
        0% {opacity:0;}
        100% {opaciity:1}
      }
    .logo-cover-home-page{
      position: absolute;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.374);
      width: 300px;
      height: 300px;
      top:240px;
      left: 39%;
      right: 0;
      margin: 0px auto;
      color:white;
      padding-top: 100px;
      font-size: 70px;
      border: 1px solid rgb(157, 157, 157);
      font-family: "NeutraDisplay-Light";
      pointer-events: none;
      animation-name: logo-cover-home-page-animation;
      animation-duration: .7s;
      
      @keyframes logo-cover-home-page-animation {
        0% {left:70%;}
        100% {left:39%}
      }
      .text-architecture-logo-cover-home-page{
        margin-top: -40px;
        font-size: 37px;
      }
    }
    .item-gadget-cover{
      fill: #fff;
      width:100%;
      height:100%;
  }
    .icon-item-gadget-cover{
     
      scale:.3;
      
  }
}