@use '../../../components/themes/Themes' as *;
@use '../../../components/deviceResolution/DeviceResolution' as *;

@media only screen and (min-width: 0) and (max-width: $tablet) {
	.main-contact {
		margin: 0px auto;
		padding-top: 100px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.form-container-contact {
		display: inline-block;
		justify-content: flex-start;
	}

	.contact-form {

		.title-contact-inside-form {
			margin-top: 10px;
			color: rgb(214, 214, 214);
			font-size: 16px;
			padding: 0px 5px 0px 0px;
			float: left;
		}

		.custom {
			border: 0;
			box-shadow: none;
			color: rgb(113, 113, 113);
			background-color: rgba(83, 83, 83, 0.147);
		}

		.line-under-form {
			width: 100%;
			height: 1px;
			background-color: $color-of-the-year;
			opacity: .2;
		}

		.btn-form-send {
			float: right;
		}
	}

	.contact-info {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.title-contact-section {
		margin: 0px auto;
		color: rgb(174, 174, 174);
		padding: 30px;
		text-transform: uppercase;
		font-family: $main-font-family;
		font-size: 12px;

	}

	.content-contact-section {
		display: flex;
		justify-content: flex-start;
		margin: 0px auto;
		color: white;
		padding: 10px 0px 10px 0px;
		font-family: $main-font-family;
		font-size: 18px;
		width: 290px;

		.icon-contact-inside-section {
			color: $color-of-the-year;
		}

		.title-contact-inside-section {
			color: $color-of-the-year;
			font-size: 13px;
			padding: 10px 5px 0px 20px;
		}

		.content-contact-inside-section {
			color: black;
			font-size: 13px;
			padding: 10px 5px 0px 5px;
		}
	}

	.element-input-contact {
		width: 280px;
		border: none;
		color: $color-of-the-year;
		font-family: $main-font-family;
		font-size: 12px;
	}

	.element-input-contact:focus {
		width: 280px;
		border: none;
		outline: none;
		color: $color-of-the-year;
		font-family: $main-font-family;
		font-size: 12px;
		font-style: italic;
		text-decoration: none;
	}

	.button-submit-contact {
		cursor:pointer;
		outline: none;
		border: 1px solid black;
		border-radius: 6px;
		background-color: transparent;
		color: $color-of-the-year;
		width: 70px;
		height: 30px;
		font-family: $main-font-family;
		font-size: 12px;
		margin-top: 28px;
		margin-left: 210px;
	}
}

@media only screen and (min-width: $tablet) and (max-width: $desktop) {
	.main-contact {
		margin-top: 160px;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.contact-container {
		margin: auto 0px;
		display: flex;
		justify-content: center;
		row-gap: 20px;
	}

	.form-container-contact {
		display: inline-block;
		justify-content: flex-start;
	}

	.contact-form {

		width: 350px;
		height: 500px;

		.title-contact-inside-form {
			margin-top: 10px;
			color: rgb(214, 214, 214);
			font-size: 16px;
			padding: 0px 5px 0px 0px;
			float: left;
		}

		.custom {
			border: 0;
			box-shadow: none;
			color: rgb(113, 113, 113);
			background-color: rgba(83, 83, 83, 0.147);
		}

		.line-under-form {
			width: 100%;
			height: 1px;
			background-color: $color-of-the-year;
			opacity: .2;
		}

		.btn-form-send {
			float: right;
		}
	}

	.contact-info {
		width: 350px;
		height: 500px;

	}

	.title-contact-section {
		margin: 0px;
		color: rgb(174, 174, 174);
		text-align: left;
		padding: 30px;
		text-transform: uppercase;
		font-family: $main-font-family;
		font-size: 12px;

	}

	.content-contact-section {
		margin: auto;
		color: white;
		text-align: left;
		padding: 16px 0px 16px 20px;
		font-family: $main-font-family;
		font-size: 18px;

		.icon-contact-inside-section {
			color: $color-of-the-year;
		}

		.title-contact-inside-section {
			color: $color-of-the-year;
			font-size: 13px;
			padding: 10px 5px 0px 20px;
		}

		.content-contact-inside-section {
			color: black;
			font-size: 13px;
			padding: 10px 5px 0px 5px;
		}
	}

	.element-input-contact {
		width: 280px;
		border: none;
		color: $color-of-the-year;
		font-family: $main-font-family;
		font-size: 12px;
	}

	.element-input-contact:focus {
		width: 280px;
		border: none;
		outline: none;
		color: $color-of-the-year;
		font-family: $main-font-family;
		font-size: 12px;
		font-style: italic;
		text-decoration: none;
	}

	.button-submit-contact {
		cursor:pointer;
		outline: none;
		border: 1px solid black;
		border-radius: 6px;
		background-color: transparent;
		color: $color-of-the-year;
		width: 70px;
		height: 30px;
		font-family: $main-font-family;
		font-size: 12px;
		margin-top: 28px;
		margin-left: 210px;
	}
}

@media only screen and (min-width: $desktop) {
	.main-contact {
		margin-top: 160px;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.contact-container {
		margin: auto 0px;
		display: flex;
		justify-content: center;
		row-gap: 20px;
	}

	.form-container-contact {
		display: inline-block;
		justify-content: flex-start;
	}

	.contact-form {

		width: 350px;
		height: 500px;

		.title-contact-inside-form {
			margin-top: 10px;
			color: rgb(214, 214, 214);
			font-size: 16px;
			padding: 0px 5px 0px 0px;
			float: left;
		}

		.custom {
			border: 0;
			box-shadow: none;
			color: rgb(113, 113, 113);
			background-color: rgba(83, 83, 83, 0.147);
		}

		.line-under-form {
			width: 100%;
			height: 1px;
			background-color: $color-of-the-year;
			opacity: .2;
		}

		.btn-form-send {
			float: right;
		}
	}

	.contact-info {
		width: 350px;
		height: 500px;

	}

	.title-contact-section {
		margin: 0px;
		color: rgb(174, 174, 174);
		text-align: left;
		padding: 30px;
		text-transform: uppercase;
		font-family: $main-font-family;
		font-size: 12px;

	}

	.content-contact-section {
		margin: auto;
		color: white;
		text-align: left;
		padding: 16px 0px 16px 20px;
		font-family: $main-font-family;
		font-size: 18px;

		.icon-contact-inside-section {
			color: $color-of-the-year;
		}

		.title-contact-inside-section {
			color: $color-of-the-year;
			font-size: 13px;
			padding: 10px 5px 0px 20px;
		}

		.content-contact-inside-section {
			color: black;
			font-size: 13px;
			padding: 10px 5px 0px 5px;
		}
	}

	.element-input-contact {
		width: 280px;
		border: none;
		color: $color-of-the-year;
		font-family: $main-font-family;
		font-size: 12px;
	}

	.element-input-contact:focus {
		width: 280px;
		border: none;
		outline: none;
		color: $color-of-the-year;
		font-family: $main-font-family;
		font-size: 12px;
		font-style: italic;
		text-decoration: none;
	}

	.button-submit-contact {
		cursor:pointer;
		outline: none;
		border: 1px solid black;
		border-radius: 6px;
		background-color: transparent;
		color: $color-of-the-year;
		width: 70px;
		height: 30px;
		font-family: $main-font-family;
		font-size: 12px;
		margin-top: 28px;
		margin-left: 210px;
	}
}