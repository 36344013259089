
  .main-container-blog{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 120px;
  
  }

  .content-article{
    width:300px;
    height: 370px;
    background-color: rgb(247, 247, 247);
    margin: 0px 30px 160px 30px;
    }
    .short-description-article{
      position: relative;
      color: rgb(128, 128, 128);
      font-size: 15px;
      height: 90px;
      margin: 20px 0px;
    }

    .roll{
      position: relative;
      color: rgb(250, 0, 117);
      font-weight: bold;
      font-size: 16px;
    }

    .bg-article{
      margin-top: 30px;
        position: relative;
        background-color: rgb(40, 40, 40);
        height: 70px;
        padding: 20px 0px 0px 0px;
        .read-more-article{
          color: rgb(255, 0, 157);
        }
        border-radius: 10px;
  }

  .title-article-border{
    margin: -10px;
    
    height: 120px;
    text-align: center;
    
   
    border: 1px solid rgb(255, 1, 120);
    border-spacing: 45px;
    border-radius: 5px;

    .title-article{
      padding: 16px;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      
     
    }
    
  }
  .image-description-article{
    margin-top: 20px;
    display: flex;
    width: 100%;
    height: 140px;
    background-color: aqua;
  }
  
