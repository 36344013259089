@use '../deviceResolution/DeviceResolution' as *;

@media only screen and (min-width: 0px){
    .hieracky-showed{
        float: left;
        padding: 14px 0px 0px 0px;
        font-size: 15px;
        text-transform: lowercase;
        font-family: "Mingzat-Regular";
        color: rgb(255, 0, 102);
    }

    .hieracky-hidden{
        display: none;
    }

    .first-simbol-between-pages{
        display: inline;
        padding: 0px 8px 0px 8px;
    }

    .second-simbol-between-pages{
        display: inline;
        padding: 0px 8px 0px 8px;
    }

    .first-simbol-between-pages-hide,
    .second-simbol-between-pages-hide{
        display: none;
        transform:translate(0px, 50px)
    }

    .first-page-hide,
    .second-page-hide,
    .third-page-hide{
        display:none;
        transform:translate(0px, 50px)
    }

    .first-page
    {
        display:inline;
        color: rgb(255, 0, 128);

    }

    .second-page{
        display:inline;
        color: rgb(255, 136, 195);
    }

    .third-page{
        display:inline;
        color: rgb(255, 185, 220);
    }
}

@media only screen and (min-width: $tablet){
    .hieracky-showed{
        float: left;
        padding: 14px 0px 0px 0px;
        font-size: 15px;
        text-transform: lowercase;
        font-family: "Mingzat-Regular";
        color: rgb(255, 0, 102);
    }

    .hieracky-hidden{
        display: none;
    }

    .first-simbol-between-pages{
        display: inline;
        padding: 0px 8px 0px 8px;
    }

    .second-simbol-between-pages{
        display: inline;
        padding: 0px 8px 0px 8px;
    }

    .first-simbol-between-pages-hide,
    .second-simbol-between-pages-hide{
        display: none;
        transform:translate(0px, 50px)
    }

    .first-page-hide,
    .second-page-hide,
    .third-page-hide{
        display:none;
        transform:translate(0px, 50px)
    }

    .first-page
    {
        display:inline;
        color: rgb(255, 0, 128);

    }

    .second-page{
        display:inline;
        color: rgb(255, 136, 195);
    }

    .third-page{
        display:inline;
        color: rgb(255, 185, 220);
    }
}

@media only screen and (min-width: $desktop){
    .hieracky-showed{
        float: left;
        padding: 14px 0px 0px 0px;
        font-size: 15px;
        text-transform: lowercase;
        font-family: "Mingzat-Regular";
        color: rgb(255, 0, 102);
    }

    .hieracky-hidden{
        display: none;
    }

    .first-simbol-between-pages{
        display: inline;
        padding: 0px 8px 0px 8px;
    }

    .second-simbol-between-pages{
        display: inline;
        padding: 0px 8px 0px 8px;
    }

    .first-simbol-between-pages-hide,
    .second-simbol-between-pages-hide{
        display: none;
        transform:translate(0px, 50px)
    }

    .first-page-hide,
    .second-page-hide,
    .third-page-hide{
        display:none;
        transform:translate(0px, 50px)
    }

    .first-page
    {
        display:inline;
        color: rgb(255, 0, 128);

    }

    .second-page{
        display:inline;
        color: rgb(255, 136, 195);
    }

    .third-page{
        display:inline;
        color: rgb(255, 185, 220);
    }
}