@use '../../../../components/deviceResolution/DeviceResolution' as *;
@use '../../../../components/themes/Themes' as *;

@media only screen and (min-width: 0px) and (max-width: $tablet) {
  .container-configurator {

    font-family: "Comfortaa-Regular";
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0px auto 0px auto;
    padding-top: 130px;

  }

  :root {
    --index-child: '50px';
  }

  .tabs {

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6px;
    font-size: var(--index-child);

    .childTab {
      border: 1px solid;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;

    }
  }

  .hide-content-tabs>* {
    display: none;


  }

  .show-content-tabs-1 {
    &:not(:nth-child(1)) {
      display: none;
    }
  }

  .show-content-tabs-2 {
    &:not(:nth-child(2)) {
      display: none;
    }
  }

  .show-content-tabs-3 {
    &:not(:nth-child(3)) {
      display: none;
    }
  }

  .show-content-tabs-4 {
    &:not(:nth-child(4)) {
      display: none;
    }
  }

  .arrows {
    position: relative;
    margin: 0px auto 0px auto;
    display: flex;
    justify-content: space-between;
    width: 100px;
    cursor: pointer;
  }

  .container-steps-show {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .container-steps-hide {
    display: none;
    border: 2px solid;
    padding: 30px;
    border-radius: 20px;
  }

  .active-left-arrow {
    opacity: 1;
    cursor: pointer;
  }

  .inactive-left-arrow {
    opacity: .5;
    cursor: default;
  }

  .active-right-arrow {
    opacity: 1;
    cursor: pointer;
  }

  .inactive-right-arrow {
    opacity: .5;
    cursor: default;
  }

  .selected-content-1 {

    width: 100px;
    height: 50px;

    background: #555;

    :nth-child(1) {
      background-color: $color-of-the-year;
    }
  }

  .selected-content-2 {

    background: #32557f;
    width: 200px;
    height: 100px;
    border-radius: 50%;

    :nth-child(1) {
      background-color: $color-of-the-year;
    }
  }

  .selected-content-3 {
    padding: 6px;

    width: 100px;
    height: 50px;

    background: #555;

    :nth-child(1) {
      background-color: $color-of-the-year;
    }
  }

  .selected-content-4 {
    padding: 6px;
    width: 100px;
    height: 50px;

    background: #555;

    :nth-child(1) {
      background-color: $color-of-the-year;
    }
  }


  .shape-tab-step {
    width: 56px;
    height: 56px;
    background: #e0e0e0;
  }

  .text-shape-tab-step {
    position: absolute;
    margin-top: -38px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    width: 56px;
  }

  .buttons-configurator {
    margin: 30px 0px;
    display: flex;
    justify-content: center;
    gap: 0px 5px;
    z-index: 1;

    .locked-button-configurator {
      color: rgb(158, 158, 158);
      border: 1px solid rgb(133, 133, 133);
      border-radius: 3px;
      width: 100px;
      cursor: pointer;
    }

    .unlocked-button-configurator {
      color: rgb(32, 32, 32);
      border: 1px solid rgb(0, 0, 0);
      border-radius: 3px;
      width: 100px;
      cursor: pointer;
    }

    .unlocked-button-configurator:hover {
      color: rgb(44, 44, 44);
      border: 1px solid rgb(44, 44, 44);
      width: 100px;
      cursor: pointer;
    }
  }

  .preview-page-hide {
    display: none;
  }

  .preview-page-show {
    display: block;
    display: flex;
    justify-content: center;
  }
    .button-send-mail {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }

  .text-button-send-mail {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    height: 0px;
    color: rgb(158, 158, 158);
  }
  
}