@use '../themes/Themes' as *;

@media only screen and (min-width: 0px) and (max-width: 768px){
  
  .footer{
    width: 100%;
    background-color: rgb(0, 0, 0);
  }
  
  .copyright{
    margin: 0px;
    margin-bottom: 60px;
    font-family: "Mingzat-Regular";
    font-size: 14px;
    float: left;
  }
  .content-footer{
    display:inline-block
  }
  
  .line-top{
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 50px auto 0px auto;
  }
  
  .line-bottom{
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 0px auto 0px auto;
  }
  
  .category{
   
    padding-top: 50px;
    ol{
      text-align: center;
    }
    .icons-contact{
      padding: 10px;
      color: rgb(110, 110, 110);
    }
  }
  
  #category_1, #category_3, #category_4{
    float:left;
    width: 50%;
    height: auto;
  }

  #category_2{
    float:right;
    width: 50%;
    height: 600px;
  }
  .title-category{
    color: rgb(107, 107, 107);
    
  }
  
  .title-category{
    color: $color-of-the-year;
  }
  .smenu-li-text{
    color: rgb(140, 140, 140);
  }
  
}
@media only screen and (min-width: 768px) and (max-width: 1280px){
  
  .footer{
    width: 100%;
  }
  
  .copyright{
    margin: 0px;
    position: relative;
    margin-bottom: 60px;
    font-family: "Mingzat-Regular";
    font-size: 14px;
    float: left;
  }
  .content-footer{
    width: 100%;
    height: auto;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px auto;
    padding-bottom: 50px;
  }
  
  .line-top{
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 50px auto 0px auto;
  }
  
  .line-bottom{
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 0px auto 0px auto;
  }
  
  .category{
    width: 300px;
    margin: 0px 5px;
    padding-top: 50px;
    ol{
      text-align: center;
      margin: 0px auto;
    }
    .icons-contact{
      padding: 10px;
      color: rgb(110, 110, 110);
    }
  }
  
  .title-category{
    color: rgb(107, 107, 107);
    
  }
  
  .title-category{
    color: $color-of-the-year;
  }
  .smenu-li-text{
    color: rgb(140, 140, 140);
  }
  
}
@media only screen and (min-width: 1280px){

  .footer{
    width: 100%;
    height: auto;
  }
  
  .copyright{
    margin: 0px;
    position: relative;
    margin-bottom: 50px;
    font-family: "Mingzat-Regular";
    font-size: 14px;
    float: left;
  }
  .content-footer{
    width: 100%;
    height: auto;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px auto;
    padding-bottom: 50px;
  }
  
  .line-top{
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 50px auto 0px auto;
  }
  
  .line-bottom{
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 0px auto 0px auto;
  }
  
  .category{
    width: 300px;
    margin: 0px 5px;
    padding-top: 50px;
    ol{
      text-align: center;
      margin: 0px auto;
    }
    .icons-contact{
      padding: 10px;
      color: rgb(110, 110, 110);
    }
  }
  
  .title-category{
    color: rgb(107, 107, 107);
    
  }
  
  .title-category{
    color: $color-of-the-year;
  }
  .smenu-li-text{
    color: rgb(140, 140, 140);
  }
  
}
