@use '../deviceResolution/DeviceResolution' as *;

@media only screen and (min-width: 0px) and (max-width: $tablet){
  .hieracky-showed{
        float: left;
        padding: 14px 0px 0px 14px;
        font-size: 15px;
        text-transform: lowercase;
        font-family: "Mingzat-Regular";
        color: rgb(0, 0, 0);
    }

    .hieracky-hidden{
        display: none;
    }

    .first-simbol-between-pages{
        display: inline;
        padding: 0px 8px 0px 8px;
    }

    .second-simbol-between-pages{
        display: inline;
        padding: 0px 8px 0px 8px;
    }

    .first-simbol-between-pages-hide,
    .second-simbol-between-pages-hide{
        display: none;
        transform:translate(0px, 50px)
    }

    .first-page-hide,
    .second-page-hide,
    .third-page-hide{
        display:none;
        transform:translate(0px, 50px)
    }

    .first-page
    {
        display:inline;
        color: rgb(0, 0, 0);

    }

    .second-page{
        display:inline;
        color: rgb(40, 40, 40);
    }

    .third-page{
        display:inline;
        color: rgb(79, 79, 79);
    }
}
