@use '../../../../../components/deviceResolution/DeviceResolution' as *;
@use '../../../../../components/themes/Themes' as *;

@media only screen and (min-width: 0px) and (max-width: $mobile) {
    .main-step-1-mobile {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0px auto;
        width: 100%;
        height: auto;
        padding: 20px 0px;
    }
    .titleStep1-mobile {
        background-color: rgb(216, 216, 216);
        width: 100%;
    }
}

@media only screen and (min-width: $mobile) and (max-width: $tablet) {
    .main-step-1-mobile {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0px auto;
        width: 100%;
        height: auto;
        padding: 20px 0px;
    }
    .titleStep1-mobile {
        background-color: rgb(216, 216, 216);
        width: 100%;

    }
}