@use '../deviceResolution/DeviceResolution' as *;
@use '../themes/Themes' as *;

@media only screen and (min-width: $desktop){
    .hieracky-showed{
        float: left;
        padding: 32px 0px 0px 0px;
        font-size: 15px;
        text-transform: lowercase;
        font-family: "Mingzat-Regular";
    }

    .hieracky-hidden{
        display: none;
    }

    .first-simbol-between-pages{
        display: inline;
        padding: 0px 8px 0px 8px;
    }

    .second-simbol-between-pages{
        display: inline;
        padding: 0px 8px 0px 8px;
    }

    .first-simbol-between-pages-hide,
    .second-simbol-between-pages-hide{
        display: none;
        transform:translate(0px, 50px)
    }

    .first-page-hide,
    .second-page-hide,
    .third-page-hide{
        display:none;
        transform:translate(0px, 50px)
    }

    .first-page
    {
        display:inline;
        color: $color-of-the-year;

    }

    .second-page{
        display:inline;
        color: $color-of-the-year;
    }

    .third-page{
        display:inline;
        color: $color-of-the-year;
    }
}