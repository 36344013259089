@use '../themes/Themes' as *;
@use '../deviceResolution/DeviceResolution' as *;

//desktop
@media only screen and (min-width: $desktop){

  .header{
    margin: 0px auto;
    height: 73px;
  }

  #main-menu{
    display:none;
  }
 
  #logo{
    position: absolute;
    margin:10px 0px 0px 0px;
  }

  .show-title-page{
    width: 100%;
    margin-top: 1px;
  }

  .hide-title-page{
    display: none;
  }

  .text-title-page{
    color: white;
    position: absolute;
    font-size: 19px;
    font-family: "NeutraDisplay-Light";
    width:120px;
    height:40px;
    background-color: rgb(148, 148, 148);
    right:0px;
    padding-top:8px;
    border-radius: 0px 0px 6px 6px;
  }

  .mdr-text{
    font-size: 40px;
    font-family: "NeutraDisplay-Light";
    color: #1a1a1a;
  }

  .architecture-text{
    font-size: 22px;
    font-family: "NeutraDisplay-Light";
    color: #636363;
  }
    
/* -------------- sliding menu -------------*/
.hidden-items{
  transform: scale(1, 0);
}
.visible-items{
  transform: scale(1, 1);
}
/* -------------- sliding menu -------------*/
.hidden-items-with-animation{
  transform: scale(1, 0);
  transition: ease-in 0.1s;  
}

.visible-items-with-animation{
  transform: scale(1, 1);
  transition: ease-out 0.4s;
  transition-delay: 0.2s;
}

.visible-items-parent{

    display: flex;
    justify-content: space-between;
    justify-content: right;
    width:100%;
    box-shadow: 0px 1px rgb(27, 2, 21);
    
    ul{
      margin: 36px 0px 0px 0px;
      width: auto;
      list-style-type:none;
      padding: 0px;
      
      
    }
    li{
      
      margin: 0px;
      background-color: rgba(255, 0, 0, 0);
      text-decoration: none;
      height: 70px;
      
      .menu-li-text-first-letter{
  
        color: rgb(102, 102, 102);
        text-decoration: none;
        font-family: "Comfortaa-Regular";
        font-size: 15px;
        padding: 20px 20px 0px 20px;
        text-transform: uppercase;
        height:70px;
      }
      .menu-li-text-rest-of-letters{
  
        color: rgb(102, 102, 102);
        text-decoration: none;
        font-family: "Comfortaa-Regular";
        
        padding: 20px 20px 0px 20px;
        height:50px;
      }
      
      /* mouse over link */
      .link-main-menu:hover {
        
        background-color: transparent;
      }

      /* mouse over link */
      .menu-li-text-rest-of-letters:hover {
        color: $color-of-the-year;
        background-color: transparent;
        

        
      }
      
      .first-letter-menu-li-text{
        font-size: 18px;
        
      }
      .rest-of-letters-menu-li-text{
        font-size: 12px;
        color: #a0a0a0;
      }
    }
    
}







}