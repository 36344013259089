@use '../themes/Themes' as *;
@use '../deviceResolution/DeviceResolution' as *;

//tablet
@media only screen and (min-width: $tablet) and (max-width: $desktop){
  
  .header {
    margin: 0px auto 0px 0px;
    height: 80px;
  }

  #logo {
    margin: 2px 0px 0px 22px;
    position: absolute;
  }

  .hide-title-page {
    z-index:111;
    display: none;
  }

  .show-title-page {
    color: rgb(255, 255, 255);
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0px;
    right: 0px;
    
    height: 20px;
    h1{
      padding-top: 3px;
      font-size: 13px;
      font-family: "Comfortaa-Regular";
      background-color: rgba(237, 17, 101, 0.679);

    }
  }

  .mdr-text {
    font-size: 52px;
    font-family: "NeutraDisplay-Light";
    color: #e5e5e5;
  }

  .architecture-text {
    font-size: 22px;
    font-family: "NeutraDisplay-Light";
    color: #8d8d8d;
  }

  #main-menu {
    z-index:1;
    width: 100%;
    height: auto;


  }

  nav {
    width: 100%;
    height: 5em;
    background-color: #000000;
  }

  .burger-menu {
    padding: 4px 0px 0px 12px;
    top: 0px;
    right: 70px;
    position: absolute;
  }

  .burger-bar {
    width: 52px;
    height: 6px;
    background-color: rgb(255, 255, 255);
    margin: 14px 0px;
    border-radius: 10%;
    right: 0px;
  }

  .menu {
    margin: 0px;
    width: 100%;
    background-color: black;
    z-index: 10;
    position: absolute;
    padding-bottom: 20px;
    border-radius: 0px 0px 6px 6px;
    transform-origin: top center;
  }

  .link-main-menu {
    width: 100%;
    color: white;
  
  }
  .menu-li-text-rest-of-letters{
    margin-left: 0px auto;
    text-align: center;
  }
  
  .visible-items-parent {

    width: 100%;
    margin: 0px auto 0px auto;

    z-index: 10;
    position: absolute;
    display: flex;

    ul {
      font-family: "Comfortaa-Regular";
      font-size: 12px;
      margin: 0px auto;
      list-style-type: none;
      padding: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 12px;
      margin: 0px auto 0px auto;
    }

    li {
      border-radius: 4px;
      margin: 0px auto 0px auto;
      padding: 0px auto 0px auto;
      width: 86px;
      height: 86px;
      text-align: center;
      display: flex;
      align-items: center;
      color: white;
      border: 1px solid #e5e5e5;

      .menu-li-text{
        text-align: center;
        margin: 0px auto;
      }
    }
  }


  /* -------------- sliding menu -------------*/
  .hidden-items {
    transform: scale(1, 0);
    display: none;
  }

  .visible-items {
    transform: scale(1, 1);
  }

  .hidden-items-with-animation {
    transform: scale(1, 0);
  }

  .visible-items-with-animation {
    transform: scale(1, 1);
    transition: ease-in 0.2s;
  }

  .visible-items-with-animation:hover {
    transition: ease-in 0.2s;
    background-color: rgb(29, 0, 10);
  }

  /* -------------- sliding menu -------------*/
  .hidden {
    transform: scale(1, 0);
    transition: ease-in 0.2s;
  }

  .visible {
    transform: scale(1, 1);
    transition: ease-out 0.2s;
  }

  /* -------------- animations menu -------------*/
  .burger-bar.clicked:nth-child(1) {
    transform: rotate(45deg) translate(0.75em, 1.25em);
    transition: ease-out 0.2s;
  }

  .burger-bar.clicked:nth-child(2) {
    transform: scale(0.1);
    transition: ease-out 0.2s;
  }

  .burger-bar.clicked:nth-child(3) {
    transform: rotate(135deg) translate(-0.5em, 1em);
    transition: ease-out 0.2s;
  }

  .burger-bar unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  }
}