@use '../../../../components/deviceResolution/DeviceResolution' as *;

@media only screen and (min-width: 0px){
  .content-text-mobile{
    font-family: "Comfortaa-Regular";
    padding-top:120px;
  }

  .title-project-mobile{
    font-size: 14px;
  }

  .type-project-mobile{
    font-style: italic;
    text-decoration: underline;
    font-size: 12px;
  }

  .location-content-mobile{
    font-style: italic;
    font-size: 12px;
  }

  .desctiption-project-mobile{
    text-align: left;
    margin:0px auto;
    padding: 40px;
    font-size: 14px;
  }

  .images-on-project{
    padding:40px 0px 0px 40px;

  }

  .content-images-project-page{
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:10px;
  }

  .image-project-page {
    transition: .3s ease;
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 5px;
    object-fit: cover;
  }
  /* mouse over link */
  .image-project-page:hover {
    transform: scale(.94);
  }
  .parentImage{
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
  }

  .view-big-image-visible{
    display: block;
    z-index: 1;
  }
  .view-big-image-hidden{
    display: none;
  }
}
