 @use '../deviceResolution/DeviceResolution' as *;
@media only screen and (min-width: 0) and (max-width: $mobile){
.bg-console-message-visible{
    margin: 0px auto;
    position:absolute;
    top:0;
    left:0;
    right:0;
    width: 100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.372);

}
.bg-console-message-hide{
    display: none;
}

.console-message-visible{
    margin: 0px auto;;
    position:absolute;
    top:100px;
    left:0;
    right:0;
    width: 100%;
    height:140px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: show-console-sent-mail;
    animation-duration: .5s;
}
.console-message-hide{
    margin: 0px auto;;
    position:absolute;
    top:400px;
    left:0;
    right:0;
    width: 100%;
    height:140px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity:0;
    animation-name: hide-console-sent-mail;
    animation-duration: .5s;
}
.main-container-console-send-mail-hide{
display: none;
}
@keyframes show-console-sent-mail{
    0% {opacity: 0}
    100% {opacity: 1}
  }
  
  @keyframes hide-console-sent-mail{
    0% {opacity: 1}
    100% {opacity: 0}
  }
}
@media only screen and (min-width: $tablet) and (max-width: $desktop) {
.bg-console-message-visible{
    margin: 0px auto;
    position:absolute;
    top:0;
    left:0;
    right:0;
    width: 100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.372);

}
.bg-console-message-hide{
    display: none;
}

.console-message-visible{
    margin: 0px auto;;
    position:absolute;
    top:98px;
    left:0;
    right:0;
    width: 100%;
    height:140px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: show-console-sent-mail;
    animation-duration: .5s;
}
.console-message-hide{
    margin: 0px auto;;
    position:absolute;
    top:80px;
    left:0;
    right:0;
    width: 100%;
    height:140px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity:0;
    animation-name: hide-console-sent-mail;
    animation-duration: .5s;
}
.main-container-console-send-mail-hide{
display: none;
}
@keyframes show-console-sent-mail{
    0% {opacity: 0}
    100% {opacity: 1}
  }
  
  @keyframes hide-console-sent-mail{
    0% {opacity: 1}
    100% {opacity: 0}
  }
}
