
@use './components/deviceResolution/DeviceResolution' as *;

  @media only screen and (min-width: 0px){

    .mdr-web-content{
      position: relative;
      text-align: center;
      margin: 0px;
    }

    #header{
      position: relative;   
    }

    section{
      text-align: center;
      margin: 0px;
    }

    #content-pages{
      text-align: center;
      margin: 0px 0px 0px 0px;
    
    }

    #page-hieracky{
      position: absolute;
    }

    #logo-main{
      width:100px;
      height:70px;
      margin: 60px 0px 0px 60px;
    }
    
    ul, li{
      list-style-type: none;
    }
    
    ul{
      display:block;
    }
    
    li{
      margin: 0px 36px 0px 36px;
    }
  }

  @media only screen and (min-width: $tablet){
    
    .mdr-web-content{
     
      position: relative;
      text-align: center;
      margin: 0px auto 0px auto;
      
    }
    
    #header{
      position: relative;   
    }

    #content-pages{
      text-align: center;
      margin: 0px 0px 0px 0px;
      width: 100%;
    }

    #page-hieracky{
      position: absolute;
    }

    #logo-main{
      width:100px;
      height:70px;
      margin: 60px 0px 0px 60px;
    }
    
    ul, li{
      list-style-type: none;
    }
    
    ul{
      display:flex;
      justify-content: right;
    }
    
    li{
      margin: 0px 36px 0px 36px;
    }


    #footer{
    }
  }

  @media only screen and (min-width: $desktop){
    .mdr-web-content{
      width: 100%;
      padding:30px 100px 0px 100px;
      text-align: center;
      margin: 0px auto 0px auto;
    }
    
    #header{
      position: relative;      
    }

    #page-hieracky{
      position: absolute;
      margin-top: 0px;
    }

    #content-pages{
      text-align: center;
      margin: 10px 0px 0px 0px;
      width: 100%;
    }

    #logo-main{
      width:100px;
      height:70px;
      margin: 60px 0px 0px 60px;
    }
    
    ul, li{
      list-style-type: none;
    }
    
    ul{
      display:flex;
      justify-content: right;
    }
    
    li{
      margin: 0px 36px 0px 36px;
    }


    #footer{
    }
  }


