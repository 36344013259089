@use '../../../../components/themes/Themes' as *;
@use '../../../../components/deviceResolution/DeviceResolution' as *;

@media only screen and (min-width: 0px) and (max-width: $tablet){
  .container-project-page {
    margin-top:0px;
    padding-top:130px;
  }

  .box-project {
    display: flex;
    justify-content: center;
    flex-direction: column;
    
  }

  .row-project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin:10px auto;
    width: 90%;
  }

  .left-content {
    overflow: hidden;
  }

  .right-content {
    overflow: hidden;

  }

  .title-project-from-projects-page {
    width: 100%;
    font-size: 12px;
    color:black;
    text-decoration: none;
    font-family: "Comfortaa-Regular";
    text-align: left;
    padding-left: 170px;
  }


  .subtitle-project-from-projects-page {
    width: 100%;
    font-size: 12px;
    font-style: italic;
    color: rgb(184, 184, 184);
    text-decoration: none;
    font-family: "Comfortaa-Regular";
    text-align: left;
    padding-left: 170px;
  }

  .location-project-from-projects-page {
    width: 100%;
    font-size: 12px;
    font-style: italic;
    color: rgb(184, 184, 184);
    text-decoration: none;
    font-family: "Comfortaa-Regular";
    text-align: left;
    padding-left: 170px;

  }


  .thumb-project-description {
    cursor: pointer;
    margin: 0px 0px 0px 0px;
    z-index: 1;
    border-radius: 10px;
    border: 1px solid rgb(210, 210, 210);
  }

  .thumb-project-image {
    width: 140px;
    height: 140px;
    object-fit:fill;
    overflow: hidden;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border: 1px solid $color-of-the-year;
    background-color: rgb(255, 255, 255);
    margin: -146px 0px 0px 6px;
  }

  .project-image {
    transition: .3s ease;
    width: 120px;
    height: 120px;
    padding: px;
    border-radius: 2px;
    overflow: hidden;
   
  }
  .item-bg{
    height: 150px;
    padding: 8px 4px;
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(101, 101, 101, 0.2), 0 2px 4px 0 rgba(174, 174, 174, 0.19);
  }

}