@use '../themes/Themes' as *;
@use '../deviceResolution/DeviceResolution' as *;

@media only screen and (min-width: 0px) and (max-width: $tablet){
  .main-container-language-switcher{
    position: fixed;
    margin-top: 4px;
    z-index: 20;
    right: 0px;
    
    display: flex;
    flex-direction: column;
    justify-content: right;
    flex-wrap: wrap;
    
    gap: 0px;
    
    .language-btn{
      
      .link-language-btn{
        margin: 0px;
        display: flex;
        background-color: rgb(0, 0, 0);
        width: 37px;
        height: 37px;
        padding: 8px 0px 0px 10px;
      }
      
      .link-language-btn:hover{
        background-color: rgb(62, 62, 62); 
      }
      
      .language-btn-text{
        font-size: 15px;
        font-family: "Mingzat-Regular";
        color: #ffffff;
        list-style-type: none;
      }
    }
  }
}

@media only screen and (min-width: $tablet) and (max-width: $desktop){
  .main-container-language-switcher{
    position: absolute;
    margin: 0px;
    z-index: 20;
    right: 0px;
    
    display: flex;
    flex-direction: column;
    justify-content: right;
    flex-wrap: wrap;
    gap: 5px;
    
    .language-btn{
      
      .link-language-btn{
        margin: 0px;
        display: flex;
        background-color: rgb(0, 0, 0);
        width: 37px;
        height: 37px;
        padding: 7px 0px 0px 8px;
      }
      
      .link-language-btn:hover{
        background-color: rgb(43, 43, 43); 
      }
      
      .language-btn-text{
        font-size: 17px;
        font-family: "Mingzat-Regular";
        color: #ffffff;
        list-style-type: none;
      }
    }
  }
}

@media only screen and (min-width: $desktop){
  .main-container-language-switcher{
    position: absolute;
    margin: 0px;
    z-index: 20;
    right: 0px;
    
    display: flex;
    flex-direction: row;
    justify-content: right;
    flex-wrap: wrap;
    gap: 5px;
    
    .language-btn{
      
      
      .link-language-btn{
        margin: 0px;
        display: flex;
        background-color: $color-of-the-year;
        width: 28px;
        height: 28px;
        padding: 4px 0px 0px 6px;
      }

      .link-language-btn:hover{
        background-color: rgb(67, 67, 67); 
      }

      .language-btn-text{
        font-size: 14px;
        font-family: "Mingzat-Regular";
        color: #ffffff;
        list-style-type: none;
      }

      
      
    }

    
  
    


   
  }
}
 


