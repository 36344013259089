@use '../deviceResolution/DeviceResolution' as *;
@use '../themes/Themes' as *;

@media only screen and (min-width: 0px) and (max-width: $tablet) {
.preview-page{
  margin: 30px 10% 0px 10%;
  width: 94%;
  border: 1px solid rgb(145, 145, 145);
  
}
.header-preview-page{
  margin-top: 20px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title-mdr-preview-page{
  font-size: 40px;
  font-family: "NeutraDisplay-Light";
  color: #1a1a1a;
  height: 44px;
}
.title-architecture-preview-page{
  font-size: 22px;
  font-family: "NeutraDisplay-Light";
  color: #636363;
}

.content-preview-page{
  margin: 10px;
  font-size: 14px;
  font-family: "NeutraDisplay-Light";
  font-style: italic;

  .data-steps-preview{
    margin: 40px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    text-align: left;
    box-sizing: content-box;
   width: 100%;
  }

  .col-content-preview-1{
    width: 60%;
      overflow-wrap: break-word;
    
  }
  .col-content-preview-2{
    width: 40%;
      overflow-wrap: break-word;
    
  }
  .title-preview-step{
    font-style:normal;
    margin: 0px auto;
    text-align: center;
    span{
      display: flex;
      justify-content: center;
      margin: 0px;
    }
    .title{
      font-size: 11px;
      text-transform: uppercase;
      color: $color-of-the-year;
    }
  }
  .content-preview-step{
    font-style:normal;
    margin-right: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .content-preview-step-3{
    width: 100%;
    background-color: rgb(255, 101, 209);
    text-align: center;
    font-style: normal;
    font-size: 20px;
  }

  .item-preview-step-4{
    font-size: 12px;
    text-align: center;
    margin: 3px;
    padding: 8px;
    border: 1px solid rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    color: white;
  }
}

.footer-preview-page{
  margin-top: 30px;
  font-size: 16px;
  font-family: "NeutraDisplay-Light";
}
}
@media only screen and (min-width: $tablet) and (max-width: $desktop) {
.preview-page{
  width: 460px;
  height: 600px;
  border: 1px solid rgb(145, 145, 145);
  
}
.header-preview-page{
  margin-top: 40px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title-mdr-preview-page{
  font-size: 40px;
  font-family: "NeutraDisplay-Light";
  color: #1a1a1a;
  height: 44px;
}
.title-architecture-preview-page{
  font-size: 22px;
  font-family: "NeutraDisplay-Light";
  color: #636363;
}

.content-preview-page{
  margin: 30px;
  height: 300px;
  font-size: 18px;
  font-family: "NeutraDisplay-Light";
  font-style: italic;

  .data-steps-preview{
    margin: 40px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    text-align: left;
    gap:20px;
    box-sizing: content-box;
  }

  .content-preview-step-2{
    
      width: 180px; 
      overflow-wrap: break-word;
    
  }
  .title-preview-step{
    font-style:normal;
    margin-right: 6px;
  }
  .content-preview-step{
    height: 100px;
    font-style:normal;
    margin-right: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .content-preview-step-3{
    width: 160px;
    background-color: rgb(255, 101, 209);
    text-align: center;
    font-style: normal;
    font-size: 26px;
  }

  .item-preview-step-4{
    text-align: center;
    margin: 3px;
    padding: 8px;
    border: 1px solid rgb(255, 255, 255);
    background-color: rgb(231, 231, 231);
  }
}

.footer-preview-page{
  font-size: 22px;
  font-family: "NeutraDisplay-Light";
}
}
@media only screen and (min-width: $desktop){
.preview-page{
  width: 460px;
  height: 600px;
  border: 1px solid rgb(145, 145, 145);
  
}
.header-preview-page{
  margin-top: 40px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title-mdr-preview-page{
  font-size: 40px;
  font-family: "NeutraDisplay-Light";
  color: #1a1a1a;
  height: 44px;
}
.title-architecture-preview-page{
  font-size: 22px;
  font-family: "NeutraDisplay-Light";
  color: #636363;
}

.content-preview-page{
  margin: 30px;
  height: 300px;
  font-size: 18px;
  font-family: "NeutraDisplay-Light";
  font-style: italic;

  .data-steps-preview{
    margin: 40px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    text-align: left;
    gap:20px;
    box-sizing: content-box;
  }

  .content-preview-step-2{
    
      width: 180px; 
      overflow-wrap: break-word;
    
  }
  .title-preview-step{
    font-style:normal;
    margin-right: 6px;
  }
  .content-preview-step{
    height: 100px;
    font-style:normal;
    margin-right: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .content-preview-step-3{
    width: 160px;
    background-color: rgb(255, 101, 209);
    text-align: center;
    font-style: normal;
    font-size: 26px;
  }

  .item-preview-step-4{
    text-align: center;
    margin: 3px;
    padding: 8px;
    border: 1px solid rgb(255, 255, 255);
    background-color: rgb(231, 231, 231);
  }
}

.footer-preview-page{
  font-size: 22px;
  font-family: "NeutraDisplay-Light";
}
}